import React from 'react';
import { Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'

const Contact = ({primary}) => {
  return (
    <div data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
      <div className="w-full py-12 bg-white border-t-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl relative">
          <div className="flex-none md:flex justify-between items-center gap-24">
            <div className="w-full md:w-6/12">
              <img className="w-full h-full object-cover" src="/img/team.jpg" alt="" />  
            </div>
            <div className="w-full md:w-6/12 pt-12 md:pt-0">
              <h3 className="font-brand leading-none font-black text-4xl md:text-5xl text-blue mb-6">
                {primary.heading1}
              </h3>
              <div className="font-sans prose prose-lg leading-normal text-grey-6">
                <PrismicRichText field={primary.text1.richText} />
              </div>
              <div className="rounded-lg text-xl font-sans font-black text-white mt-12 bg-blue py-3 px-6 inline-block">
                <Link to="/contact/">Contact the experts</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
