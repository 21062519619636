import React from 'react';
import { Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../utils/link_resolver'

const TextImageAndVideo = ({primary}) => {
  return (
    <div className="w-full py-12" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
      <div className="w-11/12 mx-auto max-w-screen-2xl">
        <div className="flex-none md:flex justify-between gap-24">
          <div className="w-full md:w-6/12">
            {primary.video ?
              <div id="video" className="aspect-w-16 aspect-h-9">
                <iframe className="w-full h-full" title="Cuddon" width="100%" height="100%" src={`https://www.youtube.com/embed/${primary.video}?rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;showinfo=0&amp;loop=1&amp;fs=1&amp;wmode=transparent`} frameBorder="0" allowFullScreen="true"></iframe>
              </div>
            : 
              <GatsbyImage style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={primary.image1.gatsbyImageData} alt={primary.image1.alt ? primary.image1.alt : ''} />
            }
          </div>
          <div className="w-full md:w-6/12 pt-12 md:pt-0">
            <div className="font-sans prose prose-lg prose-h2:font-brand prose-h3:font-brand leading-normal">
              <PrismicRichText linkResolver={linkResolver} field={primary.text1.richText} />
            </div>
            <div className="flex gap-6">
              {primary.pdf.url ?
                <Link to={primary.pdf.url} className="rounded-lg text-xl font-sans font-bold bg-grey-e text-blue mt-12 py-3 px-6 inline-block">
                  {primary.button_pdf}
                </Link> 
              : '' }
              {primary.page_link1.uid ?
                <Link to="/contact/" className="rounded-lg text-xl font-sans font-bold bg-grey-e text-blue mt-12 py-3 px-6 inline-block">
                  {primary.button_link}
                </Link>
              : '' }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextImageAndVideo;
