import React from 'react';
import { Link } from "gatsby"

const Experts = ({items}) => {
  return (
    <div>
      <div className="w-full py-12 bg-white border-t-2 border-grey" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none md:flex justify-between items-start">
            <div className="w-full md:w-4/12 pr-12">
              <h3 className="font-brand leading-none font-black text-4xl md:text-5xl text-blue mb-6 leading-none">
                Need help? We are the experts.
              </h3>
              <div className="max-w-none font-sans prose prose-lg leading-normal text-grey-6">
                <p>Get advice from the specialists, more than 80 years' of experience, providing solutions for Marlborough and NZ.</p>
              </div>
              <div className="rounded-lg font-sans text-lg font-bold text-white mt-12 bg-blue py-3 px-6 inline-block">
                <Link to="/contact/">Contact the experts</Link>
              </div>
            </div>
            <div className="w-full md:w-8/12 pt-12 md:pt-0">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {items.map((entry,i) => {
                  return (
                  <div key={i} className="">
                    <div>
                      <h3 className="font-sans font-bold text-xl text-blue">
                        {entry.name}
                      </h3>
                      <div className="font-sans text-lg text-grey-6">
                        <p>{entry.position}</p>
                        <ul className="mt-6">
                          <li className="pb-3"><svg xmlns="http://www.w3.org/2000/svg" className="text-blue h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>  
                          <a href={`tel:${entry.phone}`} title={entry.phone} rel="noopener nofollow" className="ml-3 text-blue border-b-2 border-transparent hover:border-blue">{entry.phone}</a></li>
                          <li><svg xmlns="http://www.w3.org/2000/svg" className="text-blue h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg> 
                          <a href={`mailto:${entry.email}`} title={entry.email} rel="noopener nofollow" className="ml-3 text-blue border-b-2 border-transparent hover:border-blue">Email me</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )})}
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experts;
