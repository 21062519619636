import React from 'react';
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Brands = ({primary, items}) => {
  return (
    <div data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
      <div className="w-full pt-12 pb-6 border-t-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-between">
            <div>
              <h3 className="font-brand leading-none font-black text-4xl md:text-5xl text-blue mb-3">{primary.heading1}</h3>
              <div className="font-sans text-lg text-grey-6">
                <PrismicRichText field={primary.text1.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-2 md:grid-cols-4 justify-center items-center gap-12 md:gap-24">
            {items.map((entry,i) => {
              return (
                <div key={i}>
                  <GatsbyImage style={{ width: "100%", height: "100%", display: "inline-block" }} image={entry.image1.gatsbyImageData} alt={entry.image1.alt ? entry.image1.alt : ''} />
                </div>
              )}
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brands;
